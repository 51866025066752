/**
 * CardFact
 */

import React from 'react';
import Wysiwyg from 'Components/Wysiwyg';
import classNames from 'classnames';
import s from './CardFact.module.scss';

class CardFact extends React.PureComponent {
    render() {
        const { modifier, wysiwyg, wide, fontawesome, redIcon } = this.props;

        const classes = classNames(
            s['CardFact'],
            {[s['CardFact--Wide']]: wide },
            {[s['CardFact--HasIcon']]: fontawesome },
            {[s['CardFact--HasBackground']]: modifier },
            {[s[`CardFact--Background${modifier}`]]: modifier },
            {[s['CardFact--RedIcon']]: redIcon },
        );

        const iconSmallClasses = classNames(
            s['CardFact__Icon'],
            s['CardFact__Icon--Small'],
            {[`${fontawesome}`]: fontawesome },
        );

        const iconLargeClasses = classNames(
            s['CardFact__Icon'],
            s['CardFact__Icon--Large'],
            {[fontawesome]: fontawesome },
        );

        return (
            <div className={classes}>
                {fontawesome && (
                    <i className={iconSmallClasses} />
                )}
                <div className={s['CardFact__Wrap']}>
                    <Wysiwyg wysiwyg={wysiwyg} modifier="Small" />
                    {fontawesome && (
                        <div className={s['CardFact__IconWrap']}>
                            <i className={iconLargeClasses} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

CardFact.defaultProps = {};

export default CardFact;
